import Container from "react-bootstrap/Container";
import ListGroup from 'react-bootstrap/ListGroup';
import { Jumbotron } from "./migration";
import React from "react";
import "./skills.css"

const Skills = ({skills, heading}) => {
  if (!skills.length) return null

  return (
    <Jumbotron fluid id="skills" className="bg-light m-0">
      <Container className="">
        <h2 className="display-4 pb-5 text-center">{heading}</h2>
        <section className='section skills' id='skills'>
          <ListGroup horizontal className='skills__list'>
            {skills.map((skill) => (
              <ListGroup.Item className='skills__list-item btn btn-dark'>
                <i class={skill.icon + ' fa-2x d-inline-flex'}></i>&nbsp;
                <div>{skill.name}</div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </section>
    </Container>
    </Jumbotron>
  )
}

export default Skills;
