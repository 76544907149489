// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1dbdaa, #9b59b6, #ff7f7f",
  firstName: "Ali",
  middleName: "",
  lastName: "Jahrami",
  message: "Passionate about digital transformation",
  heroMessage: "A software engineer with over 6 years of professional experience, I develop backend and frontend solutions to solve business problems.",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/iAliJ",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/ali-j/",
    },
    {
      image: "fa-envelope",
      url: "mailto: alimjahrami@gmail.com",
    },
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/hashirshoaeb.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/Ali.png"),
  imageSize: 375,
  mainMessage:
    "I am a software engineer from Bahrain with over 6 years of professional experience, I enjoy solving business problems by developing solutions, from small scripts to large scale APIs and backend servers",
  message: "I started as a structural engineer then made the switch to software development. At the begining of my career I came up with solutions to increase the workflow of architects and engineers by developing BIM applications and automation.",
  resume: "",
};

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Organization Membership App',
    description:
      'A platform for organizations that look for networking and connecting with other members by offering various benefits and network events.',
    stack: ['React', 'JavaScript', 'Django', 'PostgreSQL'],
    sourceCode: 'https://github.com/mmaskati/fe-sei7-capstone-membership-portal',
    livePreview: '#',
    thumbnail: "/org-member-app.png",
  },
  {
    name: 'Shopping Directory And Review',
    description:
      'A Web App designed for users to explore shopping malls in Bahrain. Users can easily check mall and shop directories, and share their reviews for various stores.',
    stack: ['JavsCript', 'ExpressJS', 'MongoDB', 'Bootstrap'],
    sourceCode: 'https://github.com/iAliJ/shopper-review',
    livePreview: 'https://shopper-review-app.onrender.com/',
    thumbnail: "/shopper-review-landing.png",
  },
  {
    name: 'Shop Swift - E-Commerce Web App',
    description:
      'Shop Swift is an online marketplace for selling products. Sellers can create their shops, showcase products, and offer them for buyers to purchase.',
    stack: ['React', 'NodeJS', 'JavaScript'],
    sourceCode: 'https://github.com/iAliJ/shop-swift-frontend',
    livePreview: '#',
    thumbnail: "/shop-swift.png",
  },
]

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  heading: "Recent Projects",
  gitHubUsername: "ialij",
  reposLength: 1,
  specificRepos: ["https://github.com/mmaskati/fe-sei7-capstone-membership-portal"],
};



// // Leadership SECTION
// const leadership = {
//   show: false,
//   heading: "Leadership",
//   message:
//     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
//   images: [
//     { 
//       img: require(""), 
//       label: "First slide label", 
//       paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
//     },
//     { 
//       img: require(""), 
//       label: "Second slide label", 
//       paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
//     },
//   ],
//   imageSize: {
//     width:"615",
//     height:"450"
//   }
// };

const skills = {
  heading: "Skills",
  show: true,
  skills: [
    {name:'JavaScript', icon:'fa-brands fa-js'},
    {name:'.NET', icon:'fa-solid fa-infinity'},
    {name:'C#', icon:'fa fa-csharp'},
    {name:'Python', icon:'fa fa-python'},
    {name:'React', icon:'fa fa-react'},
    {name:'NodeJS', icon:'fa fa-nodejs'},
    {name:'PostgreSQL', icon:'fa fa-postgresql'},
    {name:'MongoDB', icon:'fa fa-leaf'},
    {name:'Git', icon:'fa fa-git'},
    {name:'Django'},
    {name:'Jest'},
    {name:'xUnit'},
  ]
}

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
    "Got questions? want to work with me on a project? or got job oppurtonity? feel free to email me or you can find me on linkedIn!",
  email: "alimjahrami@gmail.com",
};

const experiences = {
  show: false,
  heading: "Experiences",
  data: [
    {
      role: 'Software Engineer',// Here Add Company Name
      companylogo: require('../assets/img/dell.png'),
      date: 'June 2018 – Present',
    },
    {
      role: 'Front-End Developer',
      companylogo: require('../assets/img/boeing.png'),
      date: 'May 2017 – May 2018',
    },
  ]
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, repos, skills, getInTouch, experiences, projects };
