// import React, { useState, useEffect, useCallback } from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Skeleton from "react-loading-skeleton";
// import axios from "axios";

const ProjectCard = ({ value }) => {
  const {
    name,
    description,
    sourceCode,
    thumbnail,
  } = value;
  return (
    <Col className="mb-5" md={6}>
      <Card className="card shadow-lg p-3 mb-5 bg-white rounded h-100">
        <Card.Img src={thumbnail} style={{ height: '300px', objectFit: 'scale-down' }} />
        <Card.Body>
          <Card.Title className="mt-1" as="h4">{name || <Skeleton />} </Card.Title>
          <Card.Text>{(!description) ? "" : description || <Skeleton count={3} />} </Card.Text>
          {sourceCode ? <CardButtons svn_url={sourceCode} /> : <Skeleton count={2} />}
          <hr />
        </Card.Body>
      </Card>
    </Col>
  );
};

const CardButtons = ({ svn_url }) => {
  return (
    <div className="d-grid gap-2 d-md-block">
      <a
        href={`${svn_url}/archive/master.zip`}
        className="btn btn-outline-secondary mx-2"
      >
        <i className="fab fa-github" /> Download Project
      </a>
      <a href={svn_url} target=" _blank" className="btn btn-outline-secondary mx-2">
        <i className="fab fa-github" /> Repo
      </a>
    </div>
  );
};

export default ProjectCard;
